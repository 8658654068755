import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/root/workspace/src/templates/markdown-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`🧰 生产力工具`}</h1>
    <p>{` ©️ 支持正版。`}</p>
    <h2>{`📟 设备`}</h2>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`Macbook Pro`}</inlineCode>{`      💻移动开发（个人开发主力）`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`Mac mini`}</inlineCode>{`         🖥️家庭开发（家庭台式，目前半闲置）`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`Windows PC`}</inlineCode>{`       🖥️家庭办公（家庭负责目前杂活、文档、很少的游戏）`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`Synology NAS`}</inlineCode>{`     🗄️家庭存储，跑点轻应用、下载服务、备份服务等`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`Windows PC`}</inlineCode>{`       🖥️工作办公（办公室主力）`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`Linux Server`}</inlineCode>{`     ☁️跑点线上服务、部署点展示界面`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`Windows Server`}</inlineCode>{`   ☁️主要是远程桌面，偶尔应急用`}</li>
    </ol>
    <h2>{`📋 查资料、写文档的轻办公`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.office.com/"
        }}>{`Office 365`}</a>{` 文档、表格管理、演示、存储、笔记、TODO、日历等，功能完备，没有广告，沉浸专注在内容的创建上，体验完美，唯一的缺点是很贵。`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.google.com/intl/zh-CN/chrome/"
        }}>{`Chrome`}</a>{` 目前浏览器主力，干净、标准支持比较好、开发者友好，各个终端都用设置同步，而且一直习惯了。`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.microsoft.com/en-us/edge"
        }}>{`Edge`}</a>{` 换了 Chromium 之后的版本，用起来也挺好，因为支持竖向标签栏、能够导入 Chrome 的配置，商店能够直连，支持标签休眠，所以现在更多在用，而且多端同步也很棒。`}<del parentName="li">{`不过各个终端都用 Chrome 同步了，所以用的少。`}</del></li>
    </ol>
    <h2>{`🛠 开发工具相关`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://code.visualstudio.com/"
        }}>{`VSCode`}</a>{` 这个不必说，很强大的编程 IDE，用来开发前端、后端、Flutter、Python、PHP 等代码`}</li>
      <li parentName="ol"><del parentName="li"><a parentName="del" {...{
            "href": "https://developer.android.com/studio"
          }}>{`Android Studio`}</a>{` 这个更不必说，Android 原生、Flutter 相关开发`}</del></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.jetbrains.com/idea/"
        }}>{`IDEA Community`}</a>{` idea 社区版，虽然相比 AS 不是最新，但是有更多插件，支持 Java 的开发，用来开发 Android 和 Flutter 都没遇到什么问题。`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "http://p.ubug.io/draft/"
        }}>{`永不丢失的草稿`}</a>{` 对于随手新建记录的操作，自己做了这个 Web 应用，类 Notion，同时支持更多样式，历史记录等。 `}<del parentName="li">{`Notpad++ 轻量、快速、随时打开随时关闭，工具之外的东西不说，不做链接不解释`}</del></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.microsoft.com/en-us/windows-server/remote/remote-desktop-services/clients/windowsdesktop"
        }}>{`Microsoft Remote Desktop`}</a>{` 远程桌面用来偶尔远端登录，各个端都有客户端`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.mactype.net/"
        }}>{`MacType`}</a>{` 看久了 MACOS 系统，windows 总是看起来心累，用这个字体会舒服得多。`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://tortoisegit.org/"
        }}>{`TortoiseGit`}</a>{` Git 是工作必备，用这个工具能够省很多心，不过可惜 Windows Only。`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.sourcetreeapp.com/"
        }}>{`SourceTree`}</a>{` Mac 和 Linux 上都在用这个，用起来很好，但是没有右键集成所以很多时候直接命令行省事了。`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.postman.com/"
        }}>{`Postman`}</a>{` 之前曾自己写了一个类似的工具，但是功能强大或者维护上都还是要更专业的。`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://github.com/microsoft/terminal"
        }}>{`Windows Terminal`}</a>{` 微软新出的一个终端，可定制化很高。`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://github.com/Genymobile/scrcpy"
        }}>{`scrcpy`}</a>{` 一个用 ADB 镜像手机界面的小工具，非常好用`}</li>
    </ol>
    <h2>{`🧷 其他工具`}</h2>
    <ol>
      <li parentName="ol">{`Adobe 全家桶 PS、AI、AE、AME、AU，设计、图标、视频编辑都离不开的工具库。`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://getkap.co/"
        }}>{`kap`}</a>{` 录屏，开源免费的，能导出 mp4 效果比 gif 好很多，可控而且文件更小；`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.sketch.com/"
        }}>{`Sketch`}</a>{` 原型图、画图之类的需求，无论是产品设计还是基本的图表绘图都很不错，导出 svg 直接作为图片引用，不好的地方是文字空格会合并，不过少量文字可以轮廓化，图表比截图展示好很多。`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "http://asciiflow.com/"
        }}>{`asciiflow`}</a>{` 字符图使用，不用导出图片，直接使用字符画，简单图形来说更方便些`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.voidtools.com/zh-cn/"
        }}>{`Everything`}</a>{` Windows 上必不可少的工具，每次忘了在哪存的文件，简单一搜即可`}</li>
      <li parentName="ol"><del parentName="li"><a parentName="del" {...{
            "href": "https://writer.drakeet.com/"
          }}>{`纯纯写作`}</a>{` 手机上写东西除了手机自带便签就是这个了，用起来很舒服。（文件存储比较黑盒，逐渐用得少了）`}</del></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://uflow.ubug.io/"
        }}>{`Uflow`}</a>{` 相比上面这个能够自己控制、支持 Web 端、很完善的历史和暂存逻辑，类 Notion 的编辑器，所以几乎足够使用了。`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.notion.so/"
        }}>{`Notion`}</a>{` 多端同步的记录器，相比单点的输入，Notion 同时支持 Window、Mac、Android 和 Web，内容的展示也非常灵活，唯一的缺点就是历史记录属于收费功能，尤其担心某天可能无法访问无法导出的问题。`}</li>
    </ol>
    <h2>{`🛰 服务`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://coding.net/"
        }}>{`Coding`}</a>{` 一站式的研发管理平台，主要用来 Git 存储、自动构建、轻量编辑开发等。腾讯背书，用了很久，中间难用了一阵，目前越来越舒服。`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.leancloud.cn/"
        }}>{`LeanCloud`}</a>{` ServerLess，我更愿称之为 BaaS 服务，存储服务、云引擎很方便的给个人开发者服务，尤其存储服务，在大部分数据库存储都十分昂贵的现在，还能给开发版提供足够的免费服务就很值得推荐。`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://cloud.tencent.com/"
        }}>{`QCloud`}</a>{` 腾讯云，相比阿里云稍微便宜些，COS 免费额度还一直在，云函数也足够个人使用，用起来很方便。`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.qiniu.com/"
        }}>{`七牛`}</a>{` 七牛云存储，当初第一次接触的免费 BaaS 服务，接口或者 SDK 就能存取文件。`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/studio/dj"
        }}>{`踮脚看世界`}</a>{` 这个不多说`}</li>
    </ol>
    <h2>{`🎈 特别`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://uflow.ubug.io/"
        }}>{`UFlow`}</a>{` 自己闲暇开发的个人工作流，也做了配套的客户端，主要给自己做阅读/订阅收集、待办管理、笔记写作、书签等个人功能。`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "http://p.ubug.io/draft/"
        }}>{`永不丢失的草稿`}</a>{` 对于随手新建记录的操作，自己做了这个 Web 应用，类 Notion，同时支持更多样式，历史记录等，一般用来做 TODO、简单想法备忘录之类，目前纯本地的，后面有时间加上打开文件、导出之类的功能，还有 WebDav 或者 Git 后端同步的逻辑。`}</li>
    </ol>
    <hr></hr>
    <p>{`// 想起来再添加...`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      